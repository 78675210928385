@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap'); */


body {
  font-family: "Balsamiq Sans";
  margin: 0;
  font-size: 12pt;
}

body, header {
  background: #ffe3e3;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 7px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	background-color: #888;
}
